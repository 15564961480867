import React from 'react';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/core';
import useWindowSize from '../hooks/useWindowSize';
import ImVeganBtw from '../svg/imveganbtw.inline.svg';

const BLOCK_WIDTH = 150;
const BLOCK_HEIGHT = 50;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
`;

type Direction = 'forward' | 'backward';

const slide = (direction: Direction, numCols: number) => keyframes`
  0% {
    transform: translateX(${direction === 'forward' ? `-${numCols * BLOCK_WIDTH}px` : '0'});
  }
  100% {
    transform: translateX(${direction === 'forward' ? '0' : `-${numCols * BLOCK_WIDTH}px`});
  }
`;

const Row = styled.div<{ direction: Direction; numCols: number }>`
  position: relative;
  left: 0;
  display: flex;
  animation: ${(props) =>
    css`
      ${slide(props.direction, props.numCols)} ${props.numCols * 10}s linear infinite
    `};
`;

const fade = (from: number, to: number) => keyframes`
  0% {
    opacity: ${from};
  }
  50% {
    opacity: ${to};
  }
  100% {
    opacity: ${from};
  }
`;

const Block = styled.div<{ from: number; to: number }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${BLOCK_WIDTH}px;
  height: ${BLOCK_HEIGHT}px;
  opacity: ${(props) => props.to};
  animation: ${(props) =>
    css`
      ${fade(props.from, props.to)} 2s ease infinite
    `};
`;

const random = () => Math.random() * 0.8 + 0.2;

const Background: React.FC = () => {
  const size = useWindowSize();

  const columns = Math.ceil((size.width || 0) / BLOCK_WIDTH);
  const rows = Math.ceil((size.height || 0) / BLOCK_HEIGHT);

  const fromOpacity = new Array(columns).fill(undefined).map(() => random());
  const toOpacity = new Array(columns).fill(undefined).map(() => random());

  return (
    <Container>
      {new Array(rows).fill(undefined).map((_, ri) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row key={`row-${ri}`} direction={ri % 2 === 1 ? 'backward' : 'forward'} numCols={columns}>
          {/* 2x because of the animation of the row */}
          {new Array(2 * columns).fill(undefined).map((__, ci) => (
            // eslint-disable-next-line react/no-array-index-key
            <Block key={`col-${ci}`} from={fromOpacity[ci % columns]} to={toOpacity[ci % columns]}>
              <ImVeganBtw />
            </Block>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Background;
