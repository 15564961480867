import React from 'react';
import styled from '@emotion/styled';
import IndexLayout from '../layouts';
import Background from '../components/Background';
import TitleSvg from '../svg/title.inline.svg';
import VideoSvg from '../svg/video.inline.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Quicksand', serif;
  height: 100%;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 800px) {
    font-size: 80px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 50px;
  }
`;

const Link = styled.a`
  display: inline-flex;
  margin: 5px;
  color: #000;
  font-size: 16px;
  text-decoration: none;
  background-color: #fff;
  border-radius: 3px;
  align-items: center;
  padding: 7px 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 200ms ease;

  & > svg {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;
    transform: translateY(-4px);
  }
`;

const LinkContent = styled.div``;

const IndexPage = () => {
  return (
    <IndexLayout>
      <Background />
      <Container>
        <TitleSvg css={{ maxWidth: '80%' }} />
        <div>
          <Link href="https://www.dominionmovement.com/watch" title="Watch Dominion">
            <VideoSvg />
            Watch Dominion
          </Link>
          <Link href="http://www.nationearth.com/" title="Watch Dominion">
            <VideoSvg />
            Watch Earthlings
          </Link>
        </div>
      </Container>
    </IndexLayout>
  );
};

export default IndexPage;
