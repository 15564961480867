import * as React from 'react';
import { Helmet } from 'react-helmet';
import 'modern-normalize';
import '../styles/normalize';
import LayoutRoot from '../components/LayoutRoot';
import LayoutMain from '../components/LayoutMain';

const IndexLayout: React.FC = ({ children }) => (
  <LayoutRoot>
    <Helmet>
      <html lang="en" dir="ltr" />
      <meta name="title" content="I'm vegan, btw" />
      <meta name="description" content="Did I mention I'm vegan, btw?" />
      <meta name="keywords" content="vegan veganism vegans vegan circle jerk vegancirclejerk btw" />
    </Helmet>
    <LayoutMain>{children}</LayoutMain>
  </LayoutRoot>
);

export default IndexLayout;
